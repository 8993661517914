export default function (context, inject) {
    if (typeof window !== 'undefined') {
      const stripePlugin = {
        import() {
          return window['Stripe'] && window['Stripe']('pk_test_L4LzCxghDT5dy8BpHKqYggmp00Y4LgrprH', {
            locale: 'en',
          });
        },
      };

      inject('stripe', stripePlugin);
      context.app.stripe = stripePlugin;
    }
}
